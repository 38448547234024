/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import inactive from "../../assets/img/Inactive.svg";
import communication from "../../assets/img/Communication.svg";
import noinactive from "../../assets/img/no-inactive.svg";
import noactive from "../../assets/img/no-active.svg";
import ReactSimplyCarousel from "react-simply-carousel";
import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
} from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import {
  dateFormatter,
  formatAmount,
  scrollToTop,
  showToast,
} from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import ApiLoader from "../../global/ApiLoader";
import InActiveListIcon from "../../assets/img/icons/InActiveListIcon";
import ActiveListIcon from "../../assets/img/icons/ActiveListIcon";
import { LISTING_TYPE } from "../../types";
import DeleteIcon from "../../assets/img/icons/DeleteIcon";
import ActivateIcon from "../../assets/img/icons/ActivateIcon";
import EditIcon from "../../assets/img/icons/EditIcon";
import { Link } from "react-router-dom";
import MoreIcon from "../../assets/img/icons/MoreIcon";
import { useOutsideClick } from "../../hooks";
import Button from "../../global/Button";
import GlobalFooter from "../Dashboard/GlobalFooter";
import moment from "moment";
import IconPhysicalEvent from "../../assets/img/createListingIcon/physical-orange.svg";
import IconVirtualEvent from "../../assets/img/createListingIcon/virtual-blue.svg";
import ReactPaginate from "react-paginate";
import MediaCarousel from "../ViewListing/MediaCarousel";
import { gettingDistance } from "../../constants/helperConnectUser";

const MyListings = () => {
  const [active, setActive] = useState<any>("active");
  const [listing, setListing] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [openIndex, setOpenIndex] = useState<any>(null);
  const [deleteId, setDeleteId] = useState<any>(null);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showInactivePopup, setShowInactivePopup] = useState<boolean>(false);
  const [apiDetails, setApiDetails] = useState({
    prevUrl: "",
    nextUrl: "",
    pageCount: 1,
  });
  const [limit] = useState<number>(16);
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState<number>(0);
  const ref = useRef<any>(null);
  const navigate = useNavigate();

  const getListingData = () => {
    setListing([]);
    setIsLoading(true);
    makeGetCall({
      url:
        endpoints.mylisting_new +
        `?limit=${limit}&offset=${offset}&is_active=${
          active === "active" ? true : false
        }`,
        params:(userCurrentLocation.long !== 0 && userCurrentLocation.lat !== 0) ?{ point:`${userCurrentLocation.long},${userCurrentLocation.lat}`} : {},    
    })
      .then((res) => {
        if (res?.results) {
          setListing(res?.results);

          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const handlePageClick = (event: any) => {
    setIsLoading(true);
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (apiDetails.nextUrl) {
      makeNextPageCall({ url: apiDetails.nextUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setListing(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else if (apiDetails.prevUrl) {
      makeNextPageCall({ url: apiDetails.prevUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setListing(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    scrollToTop();
  };

  useEffect(() => {
    getListingData();
  }, [active, userCurrentLocation]);

  useOutsideClick(ref, () => {
    setOpenIndex(null);
  });

  const deleteDraftItem = () => {
    setShowDeletePopup(false);
    if (deleteId) {
      makeDeleteCall({
        url: endpoints.listing + `${deleteId}`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  const makeInactiveList = () => {
    if (deleteId) {
      makePatchCall({
        url: endpoints.listing + `${deleteId}/mark-inactive/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setDeleteId(null);
            showToast(res.status?.message, "success");
            setOpenIndex(null);
            getListingData();
            setShowInactivePopup(false);
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  function truncateString(str = "", length) {
    if (str?.length > length) {
      return str.substring(0, length) + "...";
    } else {
      return str;
    }
  }

  const EventImageCarousel = (data: any) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    return (
      <div className="carousal-item-custom">
        {data.item.documents?.length > 1 ? (
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            dotsNav={{
              show: true,
              containerProps: {
                className: "dots-nav-container",
                style: {
                  position: "absolute",
                  bottom: "10%",
                  gap: "6px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              itemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "rgba(255, 255, 255, 0.4)",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "white",
                },
              },
            }}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",

              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "0",
                top: "50%",
                right: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-right.svg"
                />
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",
              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "99",
                top: "50%",
                left: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-left.svg"
                />
              ),
            }}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

            {data.item?.documents.map((media: any) =>
              /\.(mp4|mov|avi)$/.test(media?.documentName?.toLowerCase()) ? (
                <>
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={data.item?.documents[0]?.uploadDocumentUrl}
                    style={{
                      borderRadius: "12px 12px 0 0",
                      objectFit: "cover",
                      width: "100%",
                      height: "230px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      window.open("/listing-two/" + data.item.id);
                    }}
                  >
                    <source src={"" || ""} type="video/mp4" />
                  </video>
                </>
              ) : (
                <img
                  src={media?.uploadDocumentUrl || "/static/expertise2.png"}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload"
                  playsInline
                  loop
                  muted
                  style={{
                    cursor: "pointer",
                    borderRadius: "12px 12px 0 0",
                    objectFit: "cover",
                    width: "100%",
                    height: "230px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + data.item.id);
                  }}
                />
              )
            )}
          </ReactSimplyCarousel>
        ) : data.item?.documents?.length == 1 ? (
          /\.(mp4|mov|avi)$/.test(
            data.item?.documents[0].documentName?.toLowerCase()
          ) ? (
            <video
              autoPlay
              playsInline
              loop
              muted
              src={data.item?.documents[0]?.uploadDocumentUrl}
              style={{
                borderRadius: "12px 12px 0 0",
                objectFit: "cover",
                width: "100%",
                height: "230px",
              }}
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
                window.open("/listing-two/" + data.item.id);
              }}
            >
              <source src={"" || ""} type="video/mp4" />
            </video>
          ) : (
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2">
                <img
                  src={
                    data.item?.documents[0].uploadDocumentUrl ||
                    "/static/expertise2.png"
                  }
                  // className="event_img"
                  style={{
                    borderRadius: "12px 12px 0 0",
                    objectFit: "cover",
                    width: "100%",
                    height: "230px",
                    cursor: "pointer",
                  }}
                  alt="event-img"
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + data.item.id);
                  }}
                />
              </div>
            </div>
          )
        ) : /\.(mp4|mov|avi)$/.test(data.item?.media?.toLowerCase()) ? (
          <>
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2">
                <video
                  disablePictureInPicture
                  src={data.item?.media}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload noplaybackrate nofullscreen"
                  // playsInline
                  // loop
                  muted
                  style={{
                    cursor: "pointer",
                    borderRadius: "12px 12px 0 0",
                    objectFit: "cover",
                    width: "100%",
                    height: "230px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + data.item.id);
                  }}
                >
                  <source src={data.item?.media} />
                </video>
              </div>
            </div>
          </>
        ) : (
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              <img
                src={data.item?.media || "/static/expertise2.png"}
                // className="event_img"
                style={{
                  borderRadius: "12px 12px 0 0",
                  objectFit: "cover",
                  width: "100%",
                  height: "230px",
                  cursor: "pointer",
                }}
                alt=""
                loading="lazy"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                  window.open("/listing-two/" + data.item.id);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
         
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("It looks like geolocation is not available in your browser.", "error");
    }
  }, []);

  return (
    <>
      <GlobalHeader2 />
      <div className="my-created-page">
        <div className="my-created-content-box">
          <div className="top-section">
            <div className="title-wrapper">
              <img
                className="back-icon-style"
                style={{ cursor: "pointer" }}
                src="/static/leftarrow.svg"
                alt=""
                onClick={() => navigate(-1)}
              />
              <span className="page-title">My Created Listings</span>
            </div>
            <span className="page-desc">
              {" "}
              Track your classes with Active Listings - all in one place.
            </span>
          </div>
          <div className="active-inactive-box">
            <div
              className="item cursor-pointer"
              onClick={() => setActive("active")}
            >
              <div>
                <img alt="" src={communication} />
              </div>
              <div className={active === "active" ? "active" : ""}>Active</div>
            </div>
            <div
              className="item cursor-pointer"
              onClick={() => setActive("inactive")}
            >
              <div>
                <img alt="" src={inactive} />
              </div>
              <div className={active === "inactive" ? "active" : ""}>
                Inactive
              </div>
            </div>
          </div>

          {isLoading ? (
            <ApiLoader />
          ) : listing?.length > 0 ? (
            isLoading ? (
              <ApiLoader />
            ) : (
              <div className="active-card-listings-box">
                <div
                  className="created-listing-wrapper dominating-css"
                  // style={{
                  //   flexWrap: "wrap",
                  //   overflowX: "unset",
                  //   padding: "0px",
                  // }}
                >
                  {listing?.map((item: any, index: any) => {
                    return (
                      <div className="carousel-card" key={item.title}>
                        <div
                          style={{
                            height: "230px",
                            width: "295px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            window.open("/listing-two/" + item?.id)
                          }
                        >
                          <MediaCarousel media={item?.documents} />
                        </div>
                        <div className="list-type-wrapper">
                          <span style={{display:"flex", justifyContent:"center" , alignItems:"center"}}>
                            {item?.listingType === "S" ||
                            item?.listingType === "SPECIAL" ? (
                              <img
                                src="/static/Sport-white.svg"
                                alt="Sport Balls"
                              />
                            ) : item?.listingType === "P" ? (
                              <img
                                src="/static/box-white.svg"
                                alt="Sport Balls"
                              />
                            ) : (
                              <img
                                src="/static/people-white.svg"
                                alt="Sport Balls"
                              />
                            )}
                          </span>
                          <span>
                            {item?.listingType === "S" ||
                            item?.listingType === "SPECIAL"
                              ? "Session"
                              : item?.listingType === "P"
                              ? "Package"
                              : "Team"}
                          </span>
                        </div>
                        {item.distance !== null && (
                          <div className="distance-wrapper">{gettingDistance(item?.distance || 0)}</div>
                        )}
                        <div className="price-wrapper">
                          {item?.listingType === LISTING_TYPE.C
                            ? "Join"
                            : item?.price || item?.monthlyCost
                            ? item?.price == "0"
                              ? "Free"
                              : `AUD ${item?.price || item?.monthlyCost}`
                            : "Free"}
                        </div>

                       {active === "active" && <div
                          className="more-popup-listing"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (openIndex === index) {
                              setOpenIndex(null);
                            } else {
                              setOpenIndex(index);
                            }
                          }}
                        >
                          <MoreIcon />
                        </div>
                  }
                        {openIndex === index ? (
                          <div
                            className="more-popup-item-wrap "
                            style={{ zIndex: "999999" }}
                            ref={ref}
                          >
                            {item.isListingEditable ? (
                              <Link
                                className="event-link-title"
                                to={`/edit-listing/${item.id}`}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="more-icon-item">
                                  <span>
                                    <EditIcon />
                                  </span>
                                  Edit Listing
                                </div>
                              </Link>
                            ) : null}
                            {item.isActive ? (
                              <div
                                className="more-icon-item mb-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setOpenIndex(null);
                                  setShowInactivePopup(true);
                                }}
                              >
                                <span>
                                  <ActivateIcon />
                                </span>
                                {item.isActive ? "Inactive" : "Activate"}
                              </div>
                            ) : null}
                            {item.isActive ? null : (
                              <div
                                style={{ marginBottom: "0px" }}
                                className="more-icon-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteId(item.id);
                                  setOpenIndex(null);
                                  setShowDeletePopup(true);
                                }}
                              >
                                <span>
                                  <DeleteIcon />
                                </span>
                                Delete Listing
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        <div style={{ padding: "16px 12px 16px 12px" }}>
                          <div
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              fontWeight: "600",
                              lineHeight: "20px",
                              color: "#25272D",
                            }}
                          >
                            {truncateString(item.title, 30)}
                          </div>
                          {item.listingEventType !== "V" && (
                            <div
                              style={{
                                marginTop: "10px",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "400",
                                lineHeight: "18px",
                                color: "#25272D",
                                display: "flex",
                              }}
                            >
                              <img
                                src="/static/ion_location-outline.svg"
                                alt=""
                              />
                              <span style={{ paddingLeft: "4px" }}>
                              {(item?.listingType === "S" ||
                              item?.listingType === "SPECIAL" || item?.listingType === "P" ) ?
                                truncateString(
                                  item?.events?.[0]?.address?.address,
                                  38
                                ) : truncateString(
                                  item?.scheduleDetails.address?.address,
                                  38
                                )

                              }
                              </span>
                            </div>
                          )}
                        {(item?.listingType === "S" ||
                              item?.listingType === "SPECIAL" || item?.listingType === "P" ) &&     <div
                            style={{
                              marginTop: "6px",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              color: "#25272D",
                              display: "flex",
                            }}
                          >
                            <img src="/static/calendar-2.svg" alt="" />
                            <span style={{ paddingLeft: "4px" }}>
                              {moment(item?.events?.[0]?.date).format(
                                "DD MMM, YYYY"
                              ) + " "}
                              <img src="/static/EmptyDot.svg" style={{ marginBottom: '3px', marginRight:"4px" }} />
                              {moment(
                                item?.events?.[0]?.startTime?.substring(0, 5),
                                ["HH:mm"]
                              ).format("hh:mmA")}{" "}
                              -{" "}
                              {moment(
                                item?.events?.[0]?.endTime?.substring(0, 5),
                                ["HH:mm"]
                              ).format("hh:mmA")}
                            </span>
                          </div>
                  }
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              marginTop: "10px",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontWeight: "500",
                              lineHeight: "16px",
                            }}
                          >
                            {" "}
                            <span style={{ color: "#6C6D6F" }}>
                              Event Type:
                            </span>
                            <span
                              style={{
                                color:
                                  item.listingEventType === "V"
                                    ? "#0099FF"
                                    : "#EE7830",
                              }}
                            >
                              {item.listingEventType === "V"
                                ? "Virtual"
                                : "Physical"}
                            </span>
                            <img
                              src={
                                item.listingEventType === "V"
                                  ? IconVirtualEvent
                                  : IconPhysicalEvent
                              }
                            />
                          </div>
                        </div>
                        {item.isActive ? (
                          <div className="activeList">
                            <span>
                              <ActiveListIcon />
                            </span>
                            Active
                          </div>
                        ) : (
                          <div className="inactiveList">
                            <span>
                              <InActiveListIcon />
                            </span>
                            Inactive
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {listing.length > 15 && (
                  <div className="paginate-style">
                    <div
                      className="pages-inner-container"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                        height: "30px",
                      }}
                    >
                      <div className="pagination-box">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={
                            <>
                              <div
                                className={
                                  Math.ceil(apiDetails.pageCount / limit) ===
                                  currentPage + 1
                                    ? "previous-button disabled"
                                    : "previous-button"
                                }
                              >
                                Next{" "}
                                <img
                                  src="/static/u_angle-left-b(1).svg"
                                  alt=""
                                />
                              </div>
                            </>
                          }
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          forcePage={currentPage}
                          pageCount={Math.ceil(apiDetails.pageCount / limit)}
                          previousLabel={
                            <>
                              <div className="previous-button">
                                Previous{" "}
                                <img src="/static/u_angle-left-b.svg" alt="" />
                              </div>
                            </>
                          }
                          renderOnZeroPageCount={null}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          ) : (
            <div className="no_data">
              <img alt="" src={active === "active" ? noactive : noinactive} />
            </div>
          )}
        </div>
      </div>
      <GlobalFooter />
      {showDeletePopup ? (
        <div className={"modal show"}>
          <div className="modal-section log_out_modal">
            <div className="modal-top-part">
              <h5>Are you sure?</h5>
              {/* <p>{STRING_CONSTANTS.logout_desc}</p> */}
            </div>
            <div className="button_option">
              <Button
                btnTxt="Delete"
                className={"backtologin"}
                onClick={deleteDraftItem}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin cancel"}
                onClick={() => setShowDeletePopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}

      {showInactivePopup ? (
        <div className="modal-cancel-event show">
          <div className="modal-section" style={{ gap: "20px" }}>
            <div className="modal-top-part">
              <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowInactivePopup(false)}
                />
              </button>

              <h5>Are You Sure</h5>
              <p>
                Once the listing is inactivated we won’t be able to recover the
                information.
              </p>
            </div>

            <Button
              btnTxt="Yes, Inative"
              className={"backtologin"}
              onClick={() => makeInactiveList()}
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowInactivePopup(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MyListings;
