import { useRef, useState, useCallback, useEffect } from "react";
import { Modal } from "react-bootstrap";
import emailIcon from "../../assets/img/footerIcon/email.svg";
import callIcon from "../../assets/img/footerIcon/call.svg";
import locationIcon from "../../assets/img/footerIcon/location.svg";
import uploadIcon from "../../assets/img/footerIcon/upload.svg";
import closeIcon from "../../assets/img/footerIcon/close.svg";
import Button from "../../global/Button";
import PhoneInput from "react-phone-input-2";
import "./style.scss";
import "./phoneInput.css";
import endpoints from "../../utils/endpoints";
import { showToast } from "../../utils";
import { makePostCall } from "../../utils/Requests";
import cross from "../../assets/img/cross.png";

const FooterPartnerPopup = ({
  show,
  onHide = () => null,
  modalType = "affiliate",
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [document, setDocument] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const [errors, setErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasReadMore, setHasReadMore] = useState(false);

  const fileInputRef = useRef(null);

  const validate = useCallback(() => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!phone) newErrors.phone = "Phone is required";
    if (!email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid";
    }
    if (!message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [name, phone, email, message]);

  const handleSubmit = useCallback(() => {
    setHasSubmitted(true);
    if (!validate()) return;

    let url;
    if (modalType === "program") {
      url = endpoints.career;
    } else if (modalType === "affiliate") {
      url = endpoints.affiliate;
    } else {
      url = endpoints.ambassador;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone_number", phone);
    formData.append("email", email);
    formData.append("message", message);
    if (document) {
      formData.append("document", document);
    }

    makePostCall({
      url: url,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setIsLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsLoading(false);
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err.message, "error");
      });
  }, [name, phone, email, message, modalType, document, validate]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fsize = file.size;
    const file_size = Math.round(fsize / 1024);
    if (file_size >= 20480) {
      alert("Max file size should be 20mb");
    } else {
      if (file) {
        setUploadFile(file);
        setDocument(file); // Directly set the file object
      }
    }
  };

  const handleChange = (setter, field) => (e) => {
    if (field === "message") {
      if (e.target?.value?.length < 250) {
        setter(e.target ? e.target.value : e);
      } else {
        setMessage(e.target.value?.slice(0, 250));
      }
    } else {
      setter(e.target ? e.target.value : e);
    }
    if (hasSubmitted) {
      validate();
    }
  };

  const TextInput = useCallback(
    ({ placeholder = "", rows = null, type = "text", error, ...props }) => {
      return (
        <div style={{ maxHeight: "88px" }}>
          {rows ? (
            <textarea
              placeholder={placeholder}
              className="input_text_footer"
              rows={rows}
              {...props}
            />
          ) : (
            <input
              type={type}
              placeholder={placeholder}
              className="input_text_footer"
              {...props}
            />
          )}
          {error && (
            <div
              className="custom_input_error"
              style={{ marginTop: rows ? "-4px" : "0" }}
            >
              {error}
            </div>
          )}
        </div>
      );
    },
    []
  );

  const FileInput = () => {
    return (
      <div className="file_input_container">
        <div className="input_click_container" onClick={handleImageClick}>
          <div>
            <img src={uploadIcon} alt="upload icon" />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onInput={handleImageChange}
              onChange={(e) => (e.target.value = "")}
            />
          </div>
          <div>Attach your document</div>
        </div>
        <div>(Max 20 MB)</div>
      </div>
    );
  };

  return (
    <Modal
      centered
      dialogClassName="footer_partner_layout"
      show={show}
      onHide={onHide}
    >
      {isLoading ? (
        <div
          className="page_loader"
          style={{ position: "absolute", width: "100%" }}
        >
          <div
            className="lds-ring"
            style={{
              margin: "0 auto",
              zIndex: "100",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: "50px" }}
                src="/static/spinner.svg"
                alt="loader"
              />
            </div>

            <div className="text__container">
              <span style={{ color: "#ee7830", fontWeight: "700" }}>
                Submitting your request....
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal.Body
        style={{
          height: "fit-content",
          position: "relative",
          filter: isLoading ? "blur(4px)" : "blur(0px)",
        }}
      >
        <div className="d-flex footer_pop_container">
          <div className="footer_pop_left">
            <div className="right_heading">
              {modalType === "affiliate" ? (
                <>
                  Join the{" "}
                  <span style={{ color: "#ee762f" }}>Fitness mates</span>{" "}
                  Affiliate Program
                </>
              ) : modalType === "program" ? (
                <>Become an Ambassador </>
              ) : (
                <>
                  Start your career with{" "}
                  <span style={{ color: "#ee762f" }}>Fitness mates</span>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "5px",
                marginBottom: "25px",
              }}
            >
              <div className="right_desc d-md-inline d-none">
                {modalType === "affiliate" ? (
                  <>
                    {" "}
                    Earn while you share your love for fitness! Join the{" "}
                    <span style={{ color: "#ee762f" }}>Fitness mates</span>{" "}
                    affiliate program and enjoy generous commissions by
                    promoting our top-rated fitness app. Get access to exclusive
                    marketing materials, real-time tracking, and dedicated
                    support. Partner with us and help others achieve their
                    fitness goals while boosting your income. Sign up today and
                    start earning!
                  </>
                ) : modalType === "program" ? (
                  <>
                    Are you passionate about fitness and love sharing your
                    journey? Join our Fitness App Ambassador Program! As an
                    ambassador, you'll get exclusive access to new features,
                    special discounts, and the opportunity to inspire a global
                    community. Help others achieve their fitness goals while
                    enjoying amazing perks and recognition. Apply now and be the
                    face of our fitness revolution!
                  </>
                ) : (
                  <div>
                    Join <span style={{ color: "#ee762f" }}>FitnessMates</span>{" "}
                    and turn your passion for fitness into a rewarding career!
                    We're looking for enthusiastic individuals to help us
                    inspire and transform lives. Enjoy a dynamic work
                    environment, growth opportunities, and the chance to make a
                    real impact. Apply now and start your journey with{" "}
                    <span style={{ color: "#ee762f" }}>FitnessMates</span>!
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "5px",
                marginBottom: "25px",
              }}
            >
              <div className="right_desc d-inline d-md-none">
                {modalType === "affiliate" ? (
                  <>
                    Earn while you share your love for fitness! Join the{" "}
                    <span style={{ color: "#ee762f" }}>Fitness mates</span>{" "}
                    affiliate program and enjoy generous commissions by
                    promoting our top-rated fitness app.
                    {!hasReadMore && (
                      <span
                        onClick={() => setHasReadMore(true)}
                        style={{
                          cursor: "pointer",
                          color: "#25272D",
                        }}
                      >{`...Read more`}</span>
                    )}
                    {hasReadMore && (
                      <>
                        Get access to exclusive marketing materials, real-time
                        tracking, and dedicated support. Partner with us and
                        help others achieve their fitness goals while boosting
                        your income. Sign up today and start earning!
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#25272D",
                          }}
                          onClick={() => setHasReadMore(false)}
                        >{` Read less`}</span>
                      </>
                    )}
                  </>
                ) : modalType === "program" ? (
                  <>
                    Are you passionate about fitness and love sharing your
                    journey? Join our Fitness App Ambassador Program!{" "}
                    {!hasReadMore && (
                      <span
                        onClick={() => setHasReadMore(true)}
                        style={{
                          cursor: "pointer",
                          color: "#25272D",
                        }}
                      >{`...Read more`}</span>
                    )}
                    {hasReadMore && (
                      <>
                        As an ambassador, you'll get exclusive access to new
                        features, special discounts, Earn while you share your
                        love for fitness! Join theand the opportunity to inspire
                        a global community. Help others achieve their fitness
                        goals while enjoying amazing perks and recognition.
                        Apply now and be the face of our fitness revolution!
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#25272D",
                          }}
                          onClick={() => setHasReadMore(false)}
                        >{` Read less`}</span>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    Join <span style={{ color: "#ee762f" }}>FitnessMates</span>{" "}
                    and turn your passion for fitness into a rewarding career!
                    We're looking for enthusiastic individuals
                    {!hasReadMore && (
                      <span
                        onClick={() => setHasReadMore(true)}
                        style={{
                          cursor: "pointer",
                          color: "#25272D",
                        }}
                      >{`...Read more`}</span>
                    )}
                    {hasReadMore && (
                      <>
                        to help us inspire and transform lives. Enjoy a dynamic
                        work environment, growth opportunities, and the chance
                        to make a real impact. Apply now and start your journey
                        with{" "}
                        <span style={{ color: "#ee762f" }}>FitnessMates</span>!
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#25272D",
                          }}
                          onClick={() => setHasReadMore(false)}
                        >{` Read less`}</span>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="d-flex flex-column gap-4">
              <div className="d-flex align-items-center gap-3 contact_data">
                <img src={callIcon} alt="call icon" />
                +61 422 054 730
              </div>
              <div className="d-flex align-items-center gap-3 contact_data">
                <img src={emailIcon} alt="email icon" />
                info@fitnessmates.com.au
              </div>
              <div className="d-flex align-items-center gap-3 contact_data">
                <img src={locationIcon} alt="location icon" />
                Sydney, Australia
              </div>
            </div>
          </div>
          <div className="footer_pop_right">
            <div className="form_wrapper">
              <div className="right_heading">Share your Details</div>
              <div className="right_form_input">
                <div>
                  <TextInput
                    type="text"
                    placeholder="Name*"
                    value={name}
                    onChange={handleChange(setName, "name")}
                    onBlur={handleChange(setName, "name")}
                    error={hasSubmitted ? errors.name : null}
                  />
                </div>
                <div>
                  <PhoneInput
                    specialLabel=""
                    placeholder="Phone*"
                    country={"au"}
                    inputStyle={{
                      borderColor: "#8F939A !important",
                    }}
                    value={phone}
                    onChange={handleChange(setPhone, "phone")}
                    onBlur={handleChange(setPhone, "phone")}
                  />
                  {hasSubmitted && errors.phone && (
                    <div className="custom_input_error">{errors.phone}</div>
                  )}
                </div>
                <div>
                  <TextInput
                    required
                    type="email"
                    placeholder="Email*"
                    onChange={handleChange(setEmail, "email")}
                    onBlur={handleChange(setEmail, "email")}
                    value={email}
                    error={hasSubmitted ? errors.email : null}
                  />
                </div>
                <div>
                  <TextInput
                    placeholder="Message*"
                    rows={3}
                    onChange={handleChange(setMessage, "message")}
                    onBlur={handleChange(setMessage, "message")}
                    value={message}
                    error={hasSubmitted ? errors.message : null}
                  />
                </div>
                <div
                  style={{
                    marginTop: errors.message ? "12px" : "0",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  {uploadFile?.name ? (
                    <>
                      <div
                        style={{
                          width: "90%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {uploadFile?.name}
                      </div>
                      <img
                        src={cross}
                        style={{
                          width: "16px",
                          height: "16px",
                          cursor: "pointer",
                        }}
                        alt=""
                        onClick={() => {
                          setUploadFile(null);
                          setDocument(null);
                        }}
                      />
                    </>
                  ) : (
                    FileInput()
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  btnTxt="Submit"
                  className="footer_pop_submit"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="close_container d-md-flex d-none" onClick={onHide}>
          <img src={closeIcon} alt="close icon" className="close_icon" />
        </div>
        <div className="close_container d-flex d-md-none" onClick={onHide}>
          <img
            src="/static/cross-black.svg"
            alt="close icon"
            className="close_icon"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FooterPartnerPopup;
