import React, { useCallback, useState } from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import { getRequiredMediaUrl, scrollToTop } from "../../../utils";

const EventImageCarousel = ({ item }: any) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [load, setLoad] = useState(false);

  const changeSlide = useCallback((index) => {
    setLoad(true);
    setActiveSlideIndex(index);
  }, [setLoad, setActiveSlideIndex]);

  // Reusable media display for videos and images
  const renderMedia = (
    media,
    isVideo,
    id,
    indexItem = 0,
    activeSlideIndex = 0,
    loadAll = false
  ) => {
    if (indexItem !== 0 && activeSlideIndex !== 0 && !loadAll) {
      return <></>;
    }
    if (isVideo) {
      return (
        <>
          <video
            src={getRequiredMediaUrl(media)}
            className="event_img"
            controls
            disablePictureInPicture
            controlsList="nodownload noplaybackrate nofullscreen"
            muted
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
              window.open(`/listing-two/${id}`);
            }}
          >
            <source src={getRequiredMediaUrl(media)} />
          </video>
        </>
      );
    }
    return (
      <img
        src={getRequiredMediaUrl(media) || "/static/expertise2.png"}
        className="event_img"
        alt=""
        loading="lazy"
        onClick={(e) => {
          e.preventDefault();
          scrollToTop();
          window.open(`/listing-two/${id}`);
        }}
        style={{ cursor: "pointer" }}
      />
    );
  };

  // Determine whether media is a video
  const isVideo = (media) => /\.(mp4|mov|avi)$/.test(
      media?.documentName?.toLowerCase() || media?.toLowerCase()
    );

  if (!item) return null;

  const { photos=[], media={uploadDocumentThumbnail:"", uploadDocument:""}, id } = item;
  const hasMultipleMedia = photos?.length > 1;

  return (
    <div className="carousal-item-custom">
      { hasMultipleMedia ? (
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={changeSlide}
          itemsToShow={1}
          itemsToScroll={1}
          dotsNav={{
            show: true,
            containerProps: {
              className: "dots-nav-container",
              style: {
                position: "absolute",
                bottom: "10%",
                gap: "6px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              },
            },
            itemBtnProps: {
              style: {
                height: 6,
                width: 6,
                borderRadius: "50%",
                border: 0,
                background: "rgba(255, 255, 255, 0.4)",
              },
            },
            activeItemBtnProps: {
              style: {
                height: 7,
                width: 7,
                borderRadius: "60%",
                border: 0,
                background: "white",
              },
            },
          }}
          forwardBtnProps={{
            className: "image-carousel-button",
            style: {
              background: "rgba(255, 255, 255, 0.4)",
              borderRadius: "50%",
              border: "none",
              cursor: "pointer",
              height: 24,
              width: 24,
              position: "absolute",
              zIndex: "0",
              top: "50%",
              right: "16px",
              translate: "0 -50%",
              //   display: "none",
              justifyContent: "center",
              alignItems: "center",
            },
            children: (
              <img
                style={{ width: "16px", height: "16px" }}
                alt="Forward"
                src="/static/chevron-black-right.svg"
              />
            ),
          }}
          backwardBtnProps={{
            className: "image-carousel-button",
            style: {
              background: "rgba(255, 255, 255, 0.4)",
              borderRadius: "50%",
              border: "none",
              cursor: "pointer",
              height: 24,
              width: 24,
              position: "absolute",
              zIndex: "99",
              top: "50%",
              left: "16px",
              translate: "0 -50%",
              //   display: "none",
              justifyContent: "center",
              alignItems: "center",
            },
            children: (
              <img
                style={{ width: "16px", height: "16px" }}
                alt="Backward"
                src="/static/chevron-black-left.svg"
              />
            ),
          }}
          speed={400}
          easing="linear"
        >
          {photos?.map((ph: any, indexItem: any) => (
            <div key={indexItem}>
              {renderMedia(
                ph,
                isVideo(ph?.documentName),
                id,
                indexItem,
                activeSlideIndex,
                load
              )}
            </div>
          ))}
        </ReactSimplyCarousel>
      ) : photos?.length === 1 ? (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              {renderMedia(
                photos[0],
                isVideo(photos[0]?.documentName),
                id
              )}
            </div>
          </div>
        </>
      ) : isVideo(media) ? (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              {renderMedia(media, true, id)}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              {renderMedia(media, false, id)}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(EventImageCarousel);
