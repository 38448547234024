import React, { useCallback, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { apiConstants } from "../../../utils/Constants";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import InputField from "../../../global/InputField";
import moment from "moment";
import { isMobile } from "react-device-detect";

// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-multi-date-picker";
import SelectInterestButton from "../../ListingComponents/SelectInterestButton";
import SelectInterestComponentFilter from "../../ListingComponents/SelectInterestComponent/SelectInterestComponentFilter";

import ApiLoader from "../../../global/ApiLoader";
import useDebounce from "../../../utils/useDebounce";
import Button from "../../../global/Button";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import GoogleLocationSearchBox from "../../../global/GoogleLocationSearchBox";
import InfiniteScroll from "react-infinite-scroll-component";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import GooglePlacesSearchBarFilterNew from "../../../global/GooglePlacesSearchBarFilterNew";
import cross from "../../../assets/img/cross.png";
import arrowdown from "../../../assets/img/Chevron.svg";
import {
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  removeLocalStorage,
  scrollToTop,
  setLocalStorage,
  showToast,
} from "../../../utils";
import { makePatchCall } from "../../../utils/Requests";
import endpoints from "../../../utils/endpoints";
import { date } from "yup";
import SingleDatePicker from "../../DatePicker/SingleDatePicker/index.tsx";
import dayjs from "dayjs";

export const handleLocation = (
  lat: string,
  lng: string,
  address: string,
  address_components: any
) => {
  const userLocation = Object.assign(
    {},
    ...address_components.map((item: any) => ({
      [item.types[0]]: item.short_name || item.long_name,
    }))
  );

  let AddressData = {
    address: address,
    lat: lat,
    lng: lng,
    zipcode: userLocation?.postal_code,
    state: userLocation?.administrative_area_level_1,
    locality: userLocation?.locality,
    country: userLocation?.country,
  };
  return AddressData;
};

const FilterPopup = (props) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({});
  const navigate = useNavigate();
  const userData = getItemFromStore("userData")?.interest || [];
  const filterOption = [
    { label: "Most popular", value: "popularity" },
    { label: "Recently added", value: "recently_added" },
    { label: "Sort A to Z", value: "title" },
    { label: "Sort Z to A", value: "-title" },
  ];
  const [startDate, setStartDate] = useState(props?.startDate || null);
  const [endDate, setEndDate] = useState(props?.endDate || null);
  const [sortSelected, setSortSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [event, setEvent] = useState<any>([]);
  const [minValue, setMinValue] = useState(props.minPrice);
  const [maxValue, setMaxValue] = useState(
    !props.maxPrice ? 100000 : props.maxPrice
  );
  const [minValue2, setMinValue2] = useState(0);
  const [maxValue2, setMaxValue2] = useState(0);
  const [submitIntrest, setSubmitIntrest] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [intrestList, setIntrestList] = useState([]);
  const [showFilterInterest, setShowFilterInterest] = useState<boolean>(false);
  const [filter, setFilter] = useState("popularity");
  const [selectedInterest, setSelectedInterest] = useState<any>(userData);
  const [nextUrl, setNextUrl] = useState(null);
  const [allAgesSelected, setAllAgesSelected] = useState(
    !props.minAge && !props.maxAge ? false : true
  );
  const [tempInterestIds, setTempInterestIds] = useState([]);
  const [tempEventType, setTempEventType] = useState(props.eventType);
  const [minAge, setMinAge] = useState(!props.minAge ? "" : props.minAge);
  const [maxAge, setMaxAge] = useState(!props.maxAge ? "" : props.maxAge);
  const [gender, setGender] = useState(!props.gender ? "" : props.gender);
  const [sortType, setSortType] = useState(props.chipType);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [isInterestTabOpen, setIsInterestTabOpen] = useState(false);
  const calRef = useRef(null);
  const calRef2 = useRef(null);

  // useEffect(() => {
  //   handleSetValue(apiConstants.selectedInterest, [...tempList]);
  // })

  const [locationData, setLocationData] = useState(props?.address || null);

  useEffect(() => {
    getIntrestList(
      { search: searchTerm || "", sort: filter || "" },
      setIntrestList,
      setNextUrl,
      setIsLoading
    );
  }, [debouncedSearchTerm, filter]);

  const handleSetValue = (field: string, value: any) => {
    setValue(field, value, { shouldDirty: true });
  };

  const handleIntrest = (intrest: any) => {
    const tempList = watch(apiConstants.selectedInterest) || [];
    const tempListIds = [...tempInterestIds];
    let index = tempList.findIndex((intr: any) => intr.id === intrest.id);
    if (index > -1) {
      tempList.splice(index, 1);
      tempListIds.splice(index, 1);
    } else {
      tempList.push(intrest);
      tempListIds.push(intrest.id);
    }

    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
  };

  const handleIntrestTwo = (levelArray) => {
    const tempList = [...levelArray];
    const tempListIds = [];
    tempList.map((intr, index) => {
      tempListIds.push(intr.id);
    });
    handleSetValue(apiConstants.selectedInterest, [...tempList]);
    setTempInterestIds([...tempListIds]);
  };
  const handleAgeSelectionChange = (event) => {
    if (event.target.value === "all") {
      setAllAgesSelected(false);
      setMaxAge("");
      setMinAge("");
    } else if (event.target.value === "specific") {
      setAllAgesSelected(true);
    }
  };
  const handleAgeSelectionChangeOnDiv = (event) => {
    if (event === "all") {
      setAllAgesSelected(false);
      setMaxAge("");
      setMinAge("");
    } else if (event === "specific") {
      setAllAgesSelected(true);
    }
  };

  const handleApplyFilters = () => {
    // handleDateChange(startDate);
  };
  const handleImageClick = () => {
    setCalendarVisible((prev) => !prev);
  };

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   setCalendarVisible(false); // Hide calendar after date is selected
  // };
  const formattedDate = selectedDate
    ? moment(selectedDate).format("MMMM D, YYYY")
    : "";

  return (
    <div className={"modal show filter-popup-container"}>
      <div
        className="modal-content v-modal new-schedule_modal handle overflow_web_schedule   max-width-model-set"
        style={{ gap: "30px", }}
      >
        <div className="modal-body">
        {isInterestTabOpen ? (
          <SelectInterestComponentFilter
            interestPicked={[]}
            handleBackClick={() => setIsInterestTabOpen(false)}
            handleAdd={async (value) => {
              handleIntrestTwo(value);
              // alert('hi');
              // await handleSetValue(apiConstants.selectedInterest, value);
              await setIsInterestTabOpen(false);
            }}
          />
        ) : (
          <>
            <div
              className="cross-btn"
              onClick={() => {
                props.onHide();
                setLocationData(null);
              }}
            >
              <img src="/static/cross_circle.png" alt="" />
            </div>
            <div className="heading">Search by Filter & Sort</div>
            <div className="options-container">
              <div className="options-wrapper">
                <div
                  className={`${sortSelected ? "" : "active"} selected-option`}
                  onClick={() => {
                    sortSelected && setSortSelected(false);
                  }}
                >
                  Filter
                </div>
                <div
                  className={`${sortSelected ? "active" : ""} selected-option`}
                  onClick={() => {
                    !sortSelected && setSortSelected(true);
                  }}
                >
                  Sort
                </div>
              </div>
            </div>
            {sortSelected ? (
              <div className="sort-container">
                <div
                  className="sort-option"
                  onClick={() => setSortType("closest")}
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="radio"
                    value={"closest"}
                    name="event"
                    defaultChecked={true}
                    checked={sortType == "closest" ? true : false}
                    onClick={() => setSortType("closest")}
                    // onChange={() => {}}
                  />
                  Radius Nearest
                </div>

                <div
                  className="sort-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSortType("price_low")}
                >
                  <input
                    className=""
                    type="radio"
                    value={"physical"}
                    name="event"
                    checked={sortType == "price_low" ? true : false}
                    onClick={() => setSortType("price_low")}
                    // onChange={() => {}}
                  />
                  Price: Low to high
                </div>
                <div
                  className="sort-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSortType("price_high")}
                >
                  <input
                    type="radio"
                    value={"Both"}
                    name="event"
                    checked={sortType == "price_high" ? true : false}
                    onClick={() => setSortType("price_high")}
                    // onChange={() => {}}
                  />
                  Price: High to Low
                </div>
                <div
                  className="sort-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSortType("popular")}
                >
                  <input
                    type="radio"
                    value={"popular"}
                    name="event"
                    checked={sortType == "popular" ? true : false}
                    onClick={() => setSortType("popular")}
                  />
                  Most Popular
                </div>
                <div
                  className="sort-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSortType("earliest")}
                >
                  <input
                    type="radio"
                    value={"Both"}
                    name="event"
                    checked={sortType == "earliest" ? true : false}
                    onClick={() => setSortType("earliest")}
                  />
                  Earliest
                </div>
              </div>
            ) : (
              <div className="filter-container">
                <div className="f-location">
                  <div className="f-loc-title f-title">Filter by location</div>
                  {/* <div className="f-loc-subtitle f-sub-title">
                Filter the event by the location you want
              </div> */}
                  {/* <div className="location-input-container" style={{ maxWidth: '100%' }}> */}
                  {/* <span className="input_label_title">Search Location</span> */}
                  {/* <GoogleLocationSearchBox
                  type="input"
                  name={apiConstants.address}
                  className="location-input"
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  inputLabel={"search location"}
                  placeholder={"search location"}
                />
                <img
                  src="/static/cross-circle-gray.svg"
                  alt=""
                  onClick={() => setValue(apiConstants.address, "")}
                  className={"cross-icon"}
                />
                <div className="location-icon-container">
                  <img src="/static/location-white.svg" alt="" />
                </div> */}
                  <GooglePlacesSearchBarFilterNew
                    onChangeLocation={(
                      lat: string,
                      lng: string,
                      address: string,
                      address_components: any,
                      e
                    ) => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      let location = handleLocation(
                        lat,
                        lng,
                        address,
                        address_components
                      );
                      props.setAddress(address);
                      setLocationData(address);
                      props.setLat(lat);
                      props.setLong(lng);
                      setValue("address", address);
                      // handleFilter();
                    }}
                    setValue={setValue}
                    handleLocation={handleLocation}
                    control={control}
                    errors={errors}
                    name={apiConstants.address}
                    label={"Search location"}
                    placeholder="Include a location"
                    handleFilter={() => {
                      // handleFilter();
                      // setSideListLocation(false);
                    }}
                    onRadiusChange={(radius) => {
                      setDistance(radius);
                      // Handle the radius value, e.g., update state or trigger other actions
                    }}
                    address={props.address}
                    hidePopup={() => setSideListLocation(false)}
                  />
                  {/* </div> */}
                </div>
                <div className="f-interest">
                  <SelectInterestButton
                    isOutside={true}
                    handleClick={() => setIsInterestTabOpen(true)}
                    list={watch(apiConstants.selectedInterest) || []}
                    handleRemoveInterest={(id) => {
                      let tempData = watch(
                        apiConstants.selectedInterest
                      )?.filter((item) => item?.id !== id);
                      handleSetValue(apiConstants.selectedInterest, tempData);
                      handleIntrestTwo(tempData);
                    }}
                  />
                  {/* <div className="f-title">Filter by interests</div> */}
                  {/* <div className="f-sub-title">Search the filter</div> */}
                  {/* <div className="interest-input-container">
                  <div className={`form_group`}>
                   
                  </div>
                  {watch(apiConstants.selectedInterest) &&
                    watch(apiConstants.selectedInterest).length > 0 && (
                      <div className="apply_btn_container">
                        <div className="flex-fill chip_container mb-4">
                          {watch(apiConstants.selectedInterest).map(
                            (item, idx) => (
                              <div
                                className="interest_chip_inner"
                                key={"chip" + idx}
                              >
                                <img
                                  src={item?.icon}
                                  style={{ height: "15px", width: "15px" }}
                                />
                                <span className="chip_text ">{item?.title}</span>
                                <img
                                  src={cross}
                                  style={{
                                    width: "16px",
                                    height: "16px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleIntrest(item);
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                </div> */}
                  <div className="wth-dorpwodn ">
                    {searchTerm && (
                      <div className="data-setup">
                        <div>
                          <div>
                            {" "}
                            <span></span>
                          </div>
                        </div>
                        {isLoading ? (
                          <ApiLoader />
                        ) : intrestList?.length ? (
                          <div>
                            <InfiniteScroll
                              className="infinite_scroll_styles"
                              dataLength={intrestList?.length}
                              next={() =>
                                fetchMoreIntrestList(
                                  nextUrl,
                                  intrestList,
                                  setNextUrl,
                                  setIntrestList,
                                  setIsLoading
                                )
                              }
                              hasMore={nextUrl ? true : false}
                              loader={<ApiLoader />}
                            >
                              {intrestList?.map((item: any, index: number) => {
                                return (
                                  <span key={index}>
                                    <label
                                      htmlFor={item.title}
                                      onClick={() => {
                                        handleIntrest(item);
                                      }}
                                      className={
                                        selectedInterest.some(
                                          (intr: any) => intr.id === item.id
                                        )
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <img
                                        src={item?.image || "/static/dance.png"}
                                        alt=""
                                        width={160}
                                        height={140}
                                      />

                                      <h4
                                        className=""
                                        style={{
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          width: "140px",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item.title}{" "}
                                        {watch(
                                          apiConstants.selectedInterest
                                        )?.some(
                                          (intr: any) => intr.id === item.id
                                        ) ? (
                                          <img
                                            className="tick"
                                            src="/static/checked.svg"
                                            alt=""
                                          />
                                        ) : (
                                          ""
                                        )}{" "}
                                      </h4>
                                    </label>
                                  </span>
                                );
                              })}
                            </InfiniteScroll>
                          </div>
                        ) : (
                          <div className="no_data">
                            <img src="/static/Search.svg" alt="" />
                            <h3>{STRING_CONSTANTS.no_result_found}</h3>
                            <p>{STRING_CONSTANTS.please_try_another_term}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="f-event-type">
              <div className="f-title">Event Type</div>
              <div className="f-sub-title">Search the type</div>
              <div className="event-options-container">
                <div
                  className="select-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setTempEventType("V")}
                >
                  <input
                    type="radio"
                    value={"virtual"}
                    name="event"
                    checked={tempEventType == "V" ? true : false}
                    onClick={() => setTempEventType("V")}
                    // onChange={() => {}}
                  />
                  Virtual
                </div>
                <div
                  className="select-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setTempEventType("P")}
                >
                  <input
                    type="radio"
                    value={"physical"}
                    name="event"
                    checked={tempEventType == "P" ? true : false}
                    onClick={() => setTempEventType("P")}

                    // onChange={() => {}}
                  />
                  Physical
                </div>
                <div
                  className="select-option"
                  style={{ cursor: "pointer" }}
                  onClick={() => setTempEventType("")}
                >
                  <input
                    type="radio"
                    value={"Both"}
                    name="event"
                    defaultChecked={true}
                    checked={tempEventType == "" ? true : false}
                    onClick={() => setTempEventType("")}

                    // onChange={() => {}}
                  />
                  Both
                </div>
              </div>
            </div> */}
                <div className="f-price-range mt-1 ">
                  <div className="f-title">Price Range</div>
                  {/* <div className="f-sub-title">Search by price</div> */}
                  <div className="f-price-container">
                    <MultiRangeSlider
                      min={0}
                      max={100000}
                      canMinMaxValueSame={false}
                      minValue={minValue}
                      maxValue={maxValue}
                      onInput={(e: ChangeResult) => {
                        setMinValue(e.minValue);
                        setMaxValue(e.maxValue);
                      }}
                      onChange={(e: ChangeResult) => {
                        setMinValue2(e.minValue);
                        setMaxValue2(e.maxValue);
                      }}
                      label={false}
                      ruler={false}
                      style={{
                        border: "none",
                        boxShadow: "none",
                        padding: "15px 10px",
                      }}
                      barLeftColor="#DCDCDC"
                      barInnerColor="#EE762F"
                      barRightColor="#DCDCDC"
                      thumbLeftColor="#EE762F"
                      thumbRightColor="#EE762F"
                      step={1}
                    />

                    <div className="range-value-container">
                      <div>${minValue}</div>
                      <div>${maxValue}</div>
                    </div>
                  </div>

                  <div className="minmax-price-container">
                    <div className="min-price price-box">
                      <label>Minimum</label>
                      <input
                        placeholder="0"
                        value={minValue}
                        style={{ width: "125px" }}
                        readOnly
                      />
                    </div>
                    <div className="max-price price-box">
                      <label>Maximum</label>
                      <input
                        placeholder="200"
                        value={maxValue}
                        readOnly
                        style={{ width: "125px" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="f-date mt-1 ">
                  <div
                    className="f-title"
                    style={{
                      marginBottom: "16px",
                    }}
                  >
                    Date
                  </div>
                  {/* <div className="f-sub-title">Search by date</div> */}
                  <div className="date-container">
                    <SingleDatePicker
                      name={"filter_start_date"}
                      // watch={watch}
                      value={props?.startDate}
                      // handleSetValue={handleSetValue}
                      label={"Start Date"}
                      onChange={(val) => {
                        props?.setStartDate(val);
                        if (props?.endDate) {
                          props?.setEndDate(null);
                        }
                      }}
                      customMinDate={dayjs()}
                    />
                    <SingleDatePicker
                      name={"filter_end_date"}
                      // watch={watch}
                      disabled={!props?.startDate}
                      value={props?.endDate}
                      // handleSetValue={handleSetValue}
                      label={"End Date"}
                      onChange={(val) => {
                        props?.setEndDate(val);
                      }}
                      customMinDate={
                        props?.startDate
                          ? dayjs(props?.startDate).add(1, "day")
                          : dayjs()
                      }
                    />
                    {/* <div className="f-date-container">
                      <div
                        className={
                          isMobile ? "d-flex flex-column inputconatiner" : ""
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          calRef.current.openCalendar();
                        }}
                      >
                        <label>Start Date:</label>
                        <DatePicker
                          ref={calRef}
                          multiple={false}
                          minDate={new Date()}
                          value={startDate}
                          showOtherDays={true}
                          calendarPosition={"top"}
                          onChange={(date) => {
                            setStartDate(date?.toDate());
                          }}
                          format="DD-MMM-YYYY"
                        />
                      </div>
                      <img
                        className="arrow-icon"
                        src={arrowdown}
                        alt="Arrow Down"
                        onClick={() => {
                          calRef.current.openCalendar();
                        }}
                      />
                    </div> */}

                    {/* <div className="f-date-container">
                      <div
                        className={
                          isMobile ? "d-flex flex-column inputconatiner" : ""
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          calRef2.current.openCalendar();
                        }}
                      >
                        <label className="">End Date:</label>
                        <DatePicker
                          ref={calRef2}
                          multiple={false}
                          minDate={new Date()}
                          value={endDate}
                          showOtherDays={true}
                          calendarPosition={"top"}
                          onChange={(date) => {
                            setEndDate(date?.toDate());
                          }}
                          format="DD-MMM-YYYY"
                        />
                      </div>

                      <img
                        className=" d-flex flex-column align-items-center arrow-icon"
                        src={arrowdown}
                        onClick={() => {
                          calRef2.current.openCalendar();
                        }}
                      />
                    </div> */}
                  </div>
                </div>
                <div className="f-age mt-1 ">
                  <div className="f-title">Age </div>
                  {/* <div className="f-sub-title">Search the filter</div> */}
                  <div className="age-options-container">
                    <div
                      className="select-option"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAgeSelectionChangeOnDiv("all")}
                    >
                      <input
                        type="radio"
                        value={"all"}
                        name="age"
                        checked={!allAgesSelected}
                        onChange={handleAgeSelectionChange}
                      />
                      All ages
                    </div>
                    <div
                      className="select-option"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAgeSelectionChangeOnDiv("specific")}
                    >
                      <input
                        type="radio"
                        value={"specific"}
                        name="age"
                        checked={allAgesSelected}
                        onChange={handleAgeSelectionChange}
                      />
                      Specific age group
                    </div>
                  </div>

                  {allAgesSelected && (
                    <div className="minmax-age-container">
                      <div className="min-age age-box">
                        <label>Minimum age</label>
                        <input
                          placeholder="Enter min age"
                          value={minAge}
                          onChange={(e) => setMinAge(e.target.value)}
                        />
                      </div>
                      <div className="max-age age-box">
                        <label>Maximum age</label>
                        <input
                          placeholder="Enter max age"
                          value={maxAge}
                          onChange={(e) => setMaxAge(e.target.value)}
                        />
                      </div>
                    </div>
                  )}
                  <div></div>
                </div>
                <div className="f-gender">
                  <div className="f-title mt-1 mb-2">Gender</div>
                  {/* <div className="f-sub-title">Search the filter</div> */}
                  <div className="gender-options-container">
                    <div
                      className="select-option"
                      onClick={() => setGender("M")}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"male"}
                        name="gender"
                        checked={gender == "M" ? true : false}
                        onChange={() => setGender("M")}
                      />
                      Male
                    </div>
                    <div
                      className="select-option"
                      onClick={() => setGender("F")}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"Female"}
                        name="gender"
                        checked={gender == "F" ? true : false}
                        onChange={() => setGender("F")}
                      />
                      Female
                    </div>
                    <div
                      className="select-option"
                      onClick={() => setGender("P")}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="radio"
                        value={"Both"}
                        name="gender"
                        checked={gender == "P" ? true : false}
                        onChange={() => setGender("P")}
                      />
                      Prefer not to say
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="apply-btn-container">
              <Button
                btnTxt="Apply"
                className="apply-btn"
                onClick={() => {
                  if (watch("address") && watch("address") !== locationData) {
                    showToast(
                      "Please select location from the suggestion.",
                      "error"
                    );
                    return;
                  } else {
                    props.setCurrentPage(0);
                    props.setApplyCount();
                    props.setInterests([...tempInterestIds]);
                    props.setGender(gender);
                    props.setMinAge(minAge);
                    props.setMaxAge(maxAge);
                    props.setMinPrice(minValue);
                    props.setMaxPrice(maxValue);
                    // props.setStartDate(watch("filter_start_date"));
                    // props.setEndDate(watch("filter_end_date"));
                    props.setEventType(tempEventType);
                    props.setChipType(sortType);
                    props.onHide();
                    props.setIsFilterActive(true);
                  }
                }}
              />
              <div
                className="clear-filter"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.setCurrentPage(0);
                  props.setApplyCount();
                  props.setInterests([]);
                  props.setGender("");
                  props.setMinAge("");
                  props.setMaxAge("");
                  props.setMinPrice("");
                  props.setMaxPrice("");
                  props.setStartDate("");
                  props.setEndDate("");
                  props.setEventType("P");
                  props.setChipType("closest");
                  props.onHide();
                  props.setIsFilterActive(false);
                  props.setAddress("");
                  setLocationData(null);
                }}
              >
                Clear Filter
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    </div>
  );
};
export default React.memo(FilterPopup);
